import { hoursUntil } from "./DateUtils";

export const arrayToCommaSeparatedString = (array: string[]) => {
  return array.reduce((text, name, index) => {
    const isNotLast = index < array.length - 1;
    if (!isNotLast && array.length == 2) {
      return text + ' and ' + name;
    } else if (isNotLast && index !== 0) {
      return text + ', ' + name;
    }
    return text + (index === 0 ? '' : ', and ') + name;
  }, '');
};

export const countWords = (input: string): number => {
  const matches = input.match(/\w+ */g);
  return matches?.length ?? 0;
};

export const escapePercent = (value: string) => value.replaceAll('%', '\\%');

export const formatCurrency = (num: number, symbol = '$', decimals = 2) => (
  `${symbol}${localizeNumber(num, decimals, true) || '0'}`
);

export const formatPhoneNumber = (
  phoneNumber: string,
  mask: 'dashes' | 'paren' | 'none' = 'dashes',
) => {
  if (!phoneNumber) {
    return '';
  }
  const phone = phoneNumber.split('#')[0];
  const alias = phoneNumber.split('#')[1];
  const phoneSuffix = alias ? `#${alias}` : '';
  const phoneNum = phone.replace(/[^0-9]+/g, '');
  switch (mask) {
    case 'dashes':
      return `${phoneNum.slice(0, 3)}-${phoneNum.slice(3, 6)}-${phoneNum.slice(6)}${phoneSuffix}`;
    case 'paren':
      return `(${phoneNum.slice(0, 3)}) ${phoneNum.slice(3, 6)}-${phoneNum.slice(6)}${phoneSuffix}`;
    case 'none':
      return `${phoneNum.toString()}${phoneSuffix}`;
  }
};

/**
 * Generate a random token using the characters [A-Za-z0-9].
 * @param length
 */
export function generateRandomToken (length: number = 20): string {
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let token = '';
  while (token.length < length) {
    token += chars[Math.floor(Math.random() * chars.length)];
  }

  return token;
}


export function getExpirationStatus (expiration?: Date) {

  if (!expiration) {
    return "No expiration date";
  }

  const hoursUntilExpired = hoursUntil(expiration);
  let expiresIn: string;
  if (hoursUntilExpired <= 0) {
    expiresIn = 'Expired';
  } else {
    expiresIn = `Expires in ${timeRemainingShort(hoursUntilExpired)}`;
  }
  return expiresIn;
}

export function getFarmerName<T extends {
  firstName: string | null,
  lastName: string | null,
  telephone?: string | null,
}> (
  user: T | undefined,
  useEmail?: boolean,
) {
  let name: string;
  if (user?.firstName && user?.lastName) {
    name = `${user.firstName} ${user.lastName}`.trim();
  } else if (user?.firstName) {
    name = user.firstName;
  } else if (useEmail && user?.telephone) {
    name = user.telephone;
  } else {
    name = '';
  }
  return name.trim();
}

export const getOriginalFileName = (nameString: string) => {
  const lastIndex = nameString?.lastIndexOf('-');
  return nameString?.substring(lastIndex + 1);
};

export const isEmptyText = (text?: string | null) => {
  return text == null || text.trim() === '';
};

export const isEmptyValue = (value?: number | string | null) => {
  return value == null || (
    typeof value === 'string' ? value.trim() === '' : isNaN(value) || value === 0
  );
};

export function isNameValid (name: string): boolean {
  return name.length >= 3;
}

export const isValidHexColor = (value: string) => {
  return !!value.match(/^#[0-9A-Fa-f]{6}$/);
};

export const localizeNumber = (num: number, length = 0, forceFixed?: boolean): string => {
  if (isNaN(num) || num === undefined) {
    return '';
  }

  // TODO: lang value should be the current language of react-i18next
  const lang = 'en'; // should be defined for iOS

  let str = Number(roundToFixed(Number(num), length)).toLocaleString(lang, {
    minimumFractionDigits: length,
    maximumFractionDigits: length,
  });
  if (!forceFixed && str.indexOf('.') > -1) {
    while (str[str.length - 1] === '0') {
      str = str.slice(0, str.length - 1);
    }
  }
  if (str.indexOf('.') === str.length - 1) {
    str = str.slice(0, str.length - 1);
  }

  return str;
};

export const pluralize = (amt: number, singularName: string) => {
  return `${amt} ${singularName}${amt !== 1 ? 's' : ''}`;
};

export function removeWhiteSpace (s: string) {
  // Replace any spaces followed by a newline followed by 4 or more spaces with a single space
  return s
    .replace(/^[ \n]*/, '')
    .replace(/[ \n]*$/, '')
    .replace(/[ ]*\n[ ]{4,}/g, ' ')
    .replace(/\n /g, '\n');
}

export const roundToFixed = (num: number, length = 3) => {
  if (isNaN(num)) {
    return '';
  }

  let n = Number(num) * (10 ** length);
  n = Math.round(n);
  n *= (10 ** -length);

  return n.toFixed(length);
};

export const sanitizedFileName = (name: string) => {
  const imageName = name?.substring(0, name.lastIndexOf('.'));
  const extension = name?.substring(name.lastIndexOf('.'));
  const regex = /[^a-zA-Z0-9]/g;
  return `${imageName.replace(regex, '')}${extension}`;
};

export function timeRemainingShort (hours: number) {
  let remaining: number = hours;
  let unit: 's' | 'min' | 'h' = 'h';

  if (hours < 1) {
    remaining = hours * 60;
    unit = 'min';
  }

  if (hours * 60 < 1) {
    remaining = hours * 60 * 60;
    unit = 's';
  }

  if (hours >= 24) {
    let remainingDays = roundToFixed(Math.floor(hours / 24), 0);
    let remainingHours = roundToFixed(hours % 24, 0);

    if (remainingHours === "24") {
      remainingDays = roundToFixed(parseInt(remainingDays) + 1, 0);
      remainingHours = "0";
    }

    return remainingHours !== "0"
      ? `${remainingDays}d${remainingHours}h`
      : `${remainingDays}d`;
  }

  return `${roundToFixed(remaining, 0)}${unit}`;
}

export const trimValue = (value: string | undefined | null | boolean | unknown) => {
  if(typeof value === 'string') return value.trim();
  return value;
};

export function truncateString (str: string, length: number) {
  if (length >= str.length) { return str; }
  return `${str.slice(0, length)}[truncated]`;
}

export const unformatPhoneNumber = (phoneNumber: string) => {
  if (!phoneNumber) {
    return "";
  }
  return phoneNumber?.replaceAll(" ", "").replaceAll("(","").replaceAll(")","").replaceAll("-", "");
};
