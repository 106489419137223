import { Card, HSpacer, Text, Toolbar, VSpacer } from '@/components/DesignSystem';
import { useUser } from '@/hooks/useUser';
import { getUserDisplayName } from '@/utilities/Farmer';
import { ApiRetailer } from '@api/interfaces';
import { Box, Pagination, Stack } from '@mui/material';
import { UserType } from '@shared/enums';
import { SharedConfig } from '@shared/SharedConfig';
import React, { Fragment, useEffect, useState } from 'react';
import { useQuery } from "react-query";
import { QueryKeys } from "@/constants/QueryKeys";
import { OrderTransactionsApi } from "@/utilities/api/OrdersApi";
import { OrderTransactionEndpoint } from "@api/endpoints/OrderTransactionEndpoint";
import { ApiOrderTransaction } from "@api/interfaces/ApiOrderTransaction";
import { CheckCircle } from "@mui/icons-material";
import { useSearch } from "@/hooks/useSearch";
import { FilterSelections } from "@/components/DesignSystem/Toolbar/interfaces";

const OrderTransactionList = ({ onError, selectedRetailer } : {
  onError: (error: string) => void,
  selectedRetailer: ApiRetailer,
}) => {
  const [page, setPage] = useState(0);
  const { debouncedSearch, setSearch } = useSearch();
  const [filterSelections, setFilterSelections] =
    useState<FilterSelections | undefined>(() => new Map());

  useEffect(() => {
    setPage(0);
  }, [debouncedSearch, filterSelections]);

  const { users: farmers } = useUser({
    limit: SharedConfig.maxPageLimit,
    userType: [UserType.Farmer],
  });

  const farmerId = Array.from(filterSelections?.get('farmerId') ?? [])[0];

  const query : OrderTransactionEndpoint.List.Query = {
    farmerId,
    page,
    retailerId: selectedRetailer.id,
    search: debouncedSearch,
  };

  const { data: listOrdersResponse } = useQuery(
    [QueryKeys.GET_ORDERS, query],
    () => OrderTransactionsApi.list(query),
    { enabled: !!query.retailerId, onError },
  );

  function formatDate (date: Date): string {
    const currentYear = new Date().getFullYear();
    const targetYear = date.getFullYear();
    const formattedDate = date.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
    return targetYear !== currentYear ? `${formattedDate}, ${targetYear}` : formattedDate;
  }

  const farmerFilterOptions = farmers?.data
    .filter(farmer => !!farmer.firstName?.trim() && !!farmer.lastName?.trim())
    .map(farmer => ({ id: farmer.id, label: `${farmer.firstName?.trim()} ${farmer.lastName?.trim()}` }));

  return (
    <>
      <Stack alignItems="flex-end" direction="row" justifyContent="space-between">
        <Box sx={{ minWidth: '600px', maxWidth: '900px' }}>
          {farmerFilterOptions && (
            <Toolbar
              filters={[
                {
                  id: 'farmerId',
                  label: 'Farmer',
                  options: farmerFilterOptions,
                  selectionMethod: 'single-select',
                },
              ]}
              onChange={({ search, selections }) => {
                if (search && search.length > 2) {
                  setSearch(search);
                }
                setFilterSelections(selections);
              }}
              retainSelectionsOnFilterChange
              testID="orders-toolbar"
              totalItems={listOrdersResponse?.total ?? 0}
              totalUnit={`${selectedRetailer.name} ERP order`}
            />
          )}
        </Box>
      </Stack>
      <VSpacer size="3" />
      {listOrdersResponse?.data.map((order: ApiOrderTransaction) => (
        <Fragment key={`order-card-${order.id}`}>
          <Card testID={`order-card-${order.id}`}>
            <Stack direction="row" justifyContent="space-between">
              <Stack>
                <Text category='overline'>{order.farmer && getUserDisplayName(order.farmer)}</Text>
                <Text category='body-xlarge'>ERP Order #{order.externalId}</Text>
                <Stack direction="row">
                  <Text category='body-large'>
                    Ordered on {formatDate(order.orderDate)}
                  </Text>
                  <HSpacer size="3"/>•<HSpacer size="3"/>
                  <Text category='body-large'>{new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                  }).format(order.totalCost)}</Text>
                </Stack>
              </Stack>
              {order.rewardsProcessed && (
                <Stack alignItems='center' direction="row">
                  <CheckCircle color='success' sx={{ width: '20px' }} />
                  <HSpacer size='3' />
                  <Text category='body-medium' color='success'>Rewards processed</Text>
                </Stack>
              )}
            </Stack>
          </Card>
          <VSpacer size='4' />
        </Fragment>
      ))}
      <Stack alignItems="center">
        <VSpacer size="9" />
        <Pagination
            count={(listOrdersResponse?.lastPage ?? 0) + 1}
            onChange={
              (event, page) => setPage(page - 1 )
            }
            page={(listOrdersResponse?.page ?? 0) + 1}
        />
      </Stack>
    </>
  );
};

export default OrderTransactionList;