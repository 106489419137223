import defaultImage
  from '@/assets/logos/reverse/icon-product-generic_dry crop_nutrition_scaled.png';
import { Button, Modal, Text, VSpacer } from "@/components/DesignSystem";
import { InnerContainer } from "@/components/shared/InnerContainer";
import { AppConfig } from "@/constants/AppConfig";
import { viewProductConstant } from "@/constants/productConstant";
import { UseActiveIngredients } from '@/hooks/useProductQuery';
import { useDeleteProductById } from "@/hooks/useProducts";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from "@mui/icons-material/Edit";
import { Box, Chip, Divider, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import DeleteConfirmation from "./DeleteConfirmation";
import { useQuery } from "react-query";
import { QueryKeys } from "@/constants/QueryKeys";
import { ProductApi } from "@/utilities/api/ProductApi";

interface ProductViewProps {
  disableActions?: boolean;
  isVisible: boolean;
  onClose: () => void;
  onEdit: (args: string) => void;
  productId?: string
}

const ViewProductModal = ({
  disableActions,
  isVisible,
  onClose,
  onEdit,
  productId,
}: ProductViewProps) => {
  const { data: product } = useQuery([QueryKeys.GET_PRODUCT, productId],
    () => ProductApi.getOneProductList(productId ?? '', true), {
      enabled: !!productId,
    });

  const { deleteProductById } = useDeleteProductById();

  const [showDeleteConfirmationDialog, setShowDeleteConfirmationDialog]=useState<boolean>(false);
  const [showSubcategoryA1, setShowSubcategoryA1]=useState<boolean>(false);
  const { activeIngredientsDataList } = UseActiveIngredients();
  const formattedSubCategoryA1Data = product?.productSubcategories?.map((item) => {
    if (item.subcategories?.label === 2) {
      return item.subcategories.id;
    }
  }).filter(Boolean).join('');
  useEffect(() => {
    let shouldShowSubcategoryA1 = false;
    if(product && product.productCategory){
      if(product.productCategory.name === 'Seed'){
        if (product && product.productSubcategories) {
          for (const item of product.productSubcategories) {
            if (
              item.subcategories?.name !== 'Seed Treatment' &&
              item.subcategories?.name !== 'Biologicals'
            ) {
              shouldShowSubcategoryA1 = true;
              break;
            }
          }
        }
      }
    }

    setShowSubcategoryA1(shouldShowSubcategoryA1);
  }, [product]);

  return (
    <Modal
      cancelButton={(props) => (
        <Button onClick={onClose} {...props}>Close</Button>
      )}
      headerAccessoryRight={!disableActions &&
          <Button
              onClick={() => {
                onEdit(product?.id ?? '');
                onClose();
              }}
              startIcon={<EditIcon fontSize="small" />}
              testID="button-demo-enabled-outline-left-accessory"
              variant="text"
          >
            {viewProductConstant.edit}
          </Button>
      }
      onClose={()=>onClose()}
      open={isVisible}
      testID="view-product-modal"
      title={product?.name ?? 'View Product'}
      width={560}
    >
      <Box>
        <InnerContainer maxWidth="xl">
          <Stack spacing={3}>
            <Stack spacing={3}>
              <Text fontSize="16px">{viewProductConstant.Manufacturer}</Text>
              <Text
                fontSize="14px"
                lineHeight="20px"
                sx={{ display: "flex", flexWrap: "wrap" }}
              >
                {product?.manufacturer?.name ?? 'N/A'}
              </Text>
            </Stack>
            <Divider />
            <Stack spacing={3}>
              <Text fontSize="16px">{viewProductConstant.retailers}</Text>
              <Text
                fontSize="14px"
                lineHeight="20px"
                sx={{ display: "flex", flexWrap: "wrap" }}
              >
                {product?.productRetailers?.map((item) => (
                  item?.retailerDetails?.name
                ))?.join(', ')}
              </Text>
            </Stack>
            <Divider />
            <Stack spacing={3}>
              <Text fontSize="16px">{viewProductConstant.categoryFilters}</Text>
              <Text
                fontSize="14px"
                lineHeight="20px"
                sx={{ display: "flex", flexWrap: "wrap" }}
              >
                {product?.productCategory?.name}
              </Text>
            </Stack>
            <Divider />
            <Stack spacing={3}>
              <Text fontSize="16px">{viewProductConstant.SubcategoryA}</Text>
              <Text
                fontSize="14px"
                lineHeight="20px"
                sx={{ display: "flex", flexWrap: "wrap" }}
              >
                {product?.productSubcategories?.map((item) => (
                  item.subcategories?.name
                )).join(', ')}
              </Text>
            </Stack>
            <Divider />
            {(product?.primaryNutrients &&
              product?.primaryNutrients?.length > 0) ? (
              <>
                <Stack spacing={3}>
                  <Text fontSize="16px">{viewProductConstant.primaryNutrient}</Text>
                  <Text
                    fontSize="14px"
                    lineHeight="20px"
                    sx={{ display: "flex", flexWrap: "wrap" }}
                  >
                    {product?.primaryNutrients?.map(({ name }) => (
                      name
                    )).join(', ')}
                  </Text>
                </Stack>
                <Divider />
              </>
              ):<></>}
            {
              (product?.activeIngredientsIds
                && product?.activeIngredientsIds?.length > 0) ? (
                <>
                  <Stack spacing={3}>
                    <Text fontSize="16px">{viewProductConstant.activeIngredient}</Text>
                    <Text
                      fontSize="14px"
                      lineHeight="20px"
                      sx={{ display: "flex", flexWrap: "wrap" }}
                    >
                      {product?.activeIngredientsIds &&
                        activeIngredientsDataList
                          .filter(({ id }: { id: string }) => (
                            product?.activeIngredientsIds?.length &&
                            product.activeIngredientsIds
                              .find((ingredientsId) => ingredientsId === id)
                          ))
                          .map(({ name }: { name: string }) => name).join(', ')
                      }
                    </Text>
                  </Stack>
                  <Divider />
                </>
                ): <></>}
            {showSubcategoryA1 &&
                <>
                    <Stack spacing={3}>
                        <Text fontSize="16px">{viewProductConstant.subcategoryA1}</Text>
                        <Text
                            fontSize="14px"
                            lineHeight="20px"
                            sx={{ display: "flex", flexWrap: "wrap" }}
                        >
                          {product?.productSubcategories?.map((item) => (
                            item?.subcategories?.subcategories?.filter((item: { id: string })=>
                              item.id === formattedSubCategoryA1Data,
                            ).map((itemA1) => itemA1.name)),
                          )}
                        </Text>
                    </Stack>
                    <Divider />
                </>
            }
            <Stack spacing={3}>
              <Text fontSize="16px">{viewProductConstant.cropType}</Text>
              <Text
                fontSize="14px"
                lineHeight="20px"
                sx={{ display: "flex", flexWrap: "wrap" }}
              >
                {product?.productCrops
                  ?.map((item) => item.crops?.name)
                  .join(', ')}
              </Text>
            </Stack>
            <Divider />
            <Stack spacing={3}>
              <Text fontSize="16px">{viewProductConstant.practice}</Text>
              <Text
                fontSize="14px"
                lineHeight="20px"
                sx={{ display: "flex", flexWrap: "wrap" }}
              >
                {product?.productPractices?.map((item) => (
                  item.practices?.name
                )).join(', ')}
              </Text>
            </Stack>
            <Divider />
            <Stack spacing={3}>
              <Text fontSize="16px">{viewProductConstant.purchaseUoM}</Text>
              <Text
                fontSize="14px"
                lineHeight="20px"
                sx={{ display: "flex", flexWrap: "wrap" }}
              >
                {product?.purchaseUom?.join(', ')}
              </Text>
            </Stack>
            <Divider />
            <Stack spacing={3}>
              <Text fontSize="16px">{viewProductConstant.packageSize}</Text>
              <Text
                fontSize="14px"
                lineHeight="20px"
                sx={{ display: "flex", flexWrap: "wrap" }}
              >
                {product?.packageSize?.join(', ')}
              </Text>
            </Stack>
            <Divider />
            <Stack spacing={3}>
              <Text fontSize="16px">{viewProductConstant.restrictedUse}</Text>
              <Text
                fontSize="14px"
                lineHeight="20px"
              >
                {product?.isRestrictedUse ? 'True' : 'False' }
              </Text>
            </Stack>
            <Divider />
            <Stack spacing={3}>
              <Text fontSize="16px">{viewProductConstant.sellSheet}</Text>
              <Text
                fontSize="14px"
                lineHeight="20px"
                sx={{ display: "flex", flexWrap: "wrap" }}
              >
                {product?.sellSheet}
              </Text>
            </Stack>
            <Divider />
            <Stack spacing={3}>
              <Text fontSize="16px">{viewProductConstant.thumbnaillink}</Text>
              <Box sx={{ display:'flex', justifyContent:"center" }} >
                <img
                  alt="image"
                  src={product?.image
                    ? `${AppConfig.staticImageHost}/${product.image}`
                    : defaultImage}
                  width="231px"
                />
              </Box>
            </Stack>
            <Divider />
            <Stack spacing={3}>
              <Text fontSize="16px">{viewProductConstant?.Keywords}</Text>
              {product?.keywords && product?.keywords?.length > 0 && (
                <Box>
                  {product.keywords.map((item: string, i: number) => {
                    return (
                      <Chip
                        key={i}
                        label={item}
                        sx={{
                          marginRight: "10px",
                          marginBottom: "10px",
                        }}
                        variant="outlined"
                      />
                    );
                  })}
                </Box>
              )}
            </Stack>
            <Divider />
            <Stack spacing={3}>
              <Box
                sx={{
                  border: "1px solid",
                  borderRadius: "3px",
                  display: "flex",
                  padding: "20px",
                  justifyContent: "space-between",
                }}
              >
                <Box>
                  <Text fontSize="17px">{viewProductConstant.companionProducts}</Text>
                  <VSpacer size="3" />
                  {product?.companionProducts &&
                    product?.companionProducts?.map(
                      ({ id, productDetails }) => (
                        <Text fontSize="11px" key={id} multiline>
                          {productDetails?.name}
                        </Text>
                      ))}
                </Box>
              </Box>
            </Stack>
            <Stack spacing={3}>
              <Box
                sx={{
                  border: "1px solid",
                  borderRadius: "3px",
                  display: "flex",
                  padding: "20px",
                  justifyContent: "space-between",
                }}
              >
                <Box>
                  <Text fontSize="17px">{viewProductConstant.alternativeProducts}</Text>
                  <VSpacer size="3" />
                  {product?.alternativeProducts &&
                    product?.alternativeProducts?.map(
                      ({ id, productDetails }) => (
                        <Text fontSize="11px" key={id} multiline>
                          {productDetails?.name}
                        </Text>
                      ))}
                </Box>
              </Box>
            </Stack>
          </Stack>
          <VSpacer size="6" />
          {!disableActions && (
            <>
              <Divider />
              <VSpacer size="5" />
              <Stack alignContent='center'>
                <Button
                  color="error"
                  onClick={()=>{
                    setShowDeleteConfirmationDialog(true);
                  }}
                  startIcon={<DeleteOutlineIcon />}
                  testID="button-demo-enabled-text"
                  variant="text"
                >
                  {viewProductConstant.deleteProduct}
                </Button>
              </Stack>
            </>
          )}
        </InnerContainer>
      </Box>
      {!disableActions && (<DeleteConfirmation
        handleDelete={() => deleteProductById(productId ?? '')}
        onClose={onClose}
        productFieldId={product?.id ?? ''}
        setShowDeleteConfirmationDialog={setShowDeleteConfirmationDialog}
        showDeleteConfirmationDialog={showDeleteConfirmationDialog}
      />)}
    </Modal>
  );
};

export default ViewProductModal;
