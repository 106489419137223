import { HSpacer, Text, VSpacer } from '@/components/DesignSystem';
import { ApiRetailer } from '@api/interfaces';
import { Alert, Container, Divider, Stack } from '@mui/material';
import React, { useState } from 'react';
import RetailerSelector from "@/components/shared/ListSelector/RetailerSelector";
import { useGetRetailerList } from "@/hooks/useProductQuery";
import PricingRequestList from "@/pages/Admin/PricingRequestList/PricingRequestList";
import OrderTransactionList from "@/pages/Admin/PricingRequestList/OrderTransactionList";

const OrdersOverview = () => {
  const [errorMessage, setErrorMessage] = useState('');

  const { retailerList: erpRetailerList } = useGetRetailerList({ isErpEnabled: true });
  const [selectedRetailer, setSelectedRetailer] = useState<ApiRetailer | undefined>();

  return (
    <Container maxWidth="lg">
      <VSpacer size="8" />
      <Stack>
        <Stack alignItems="center" direction="row">
          <Text category="headline-medium">Orders</Text>
          <HSpacer size='6' />
          {!!erpRetailerList?.length && (
            <RetailerSelector
              defaultMenuItem={{
                label: 'GROWERS',
                logo: 'growers',
              }}
              onSelect={setSelectedRetailer}
              retailers={erpRetailerList}
            />
          )}
        </Stack>
        <VSpacer size="6" />
        <Divider />
        <VSpacer size="6" />
        {!!errorMessage && (
          <Alert color="error" icon={false}>{errorMessage}</Alert>
        )}
        {selectedRetailer
          ? <OrderTransactionList onError={setErrorMessage} selectedRetailer={selectedRetailer} />
          : <PricingRequestList onError={setErrorMessage} />
        }
        <VSpacer size='14' />
      </Stack>
    </Container>
  );
};


export default OrdersOverview;