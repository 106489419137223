import { Button, HSpacer, Modal, Radio, Text, TextLink, VSpacer } from '@/components/DesignSystem';
import { FileUpload } from '@/components/shared/FileUpload';
import { QueryKeys } from '@/constants/QueryKeys';
import { useSnackbar } from '@/providers/GlobalSnackbarProvider';
import { DetailedApiError } from '@/utilities/api/DetailedApiError';
import { ImportApi } from '@/utilities/api/ImportApi';
import { downloadCsv } from '@/utilities/Export';
import { Divider, RadioGroup } from '@mui/material';
import { ImportType } from '@shared/enums';
import { Fragment, useState } from 'react';
import { useQueryClient } from 'react-query';

interface ImportErpModalProps {
  onClose: () => void,
  open: boolean,
  retailerId: string,
}

const radioOptions = ['Products', 'Customers', 'Locations', 'Salespersons', 'Orders'];
export const ImportErpModal = ({
  onClose,
  open,
  retailerId,
}: ImportErpModalProps) => {
  const [selectedOption, setSelectedOption] = useState<ImportType>();
  const [selectedFile, setSelectedFile] = useState<File>();
  const [isImporting, setIsImporting] = useState(false);
  const [importErrors, setImportErrors] = useState<{ error: string, row: number }[]>();
  const [isUpdatedAfterError, setIsUpdatedAfterError] = useState(true);
  const { openSnackbar } = useSnackbar();
  const isFormValid = !!(selectedOption && selectedFile);
  const queryClient = useQueryClient();

  const handleDownloadErrorCsv = () => {
    let csvString = '';
    let headers: string[] = [];
    importErrors?.forEach((error) => {
      const keys = Object.keys(error);
      if (!headers.length) {
        headers = keys;
        csvString += headers.join(',') + '\n';
      }
      csvString += Object.values(error).join(',') + '\n';
    });

    const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    if (link.download !== undefined) {
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', 'import-errors.csv');
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  return (
    <Modal
      acceptButton={() => (
        <Button
          disabled={!isFormValid || !isUpdatedAfterError}
          loading={isImporting}
          onClick={async () => {
            if (selectedFile && selectedOption) {
              setImportErrors(undefined);
              try {
                setIsImporting(true);
                await ImportApi.import(retailerId, selectedFile, selectedOption);
                openSnackbar('File import successful');
                await queryClient.invalidateQueries(QueryKeys.GET_IMPORT_HISTORY_LIST);
                onClose();
              } catch (error) {
                openSnackbar('File import failed');
                setImportErrors((error as DetailedApiError)?.details?.details);
                setIsUpdatedAfterError(false);
              }
              setIsImporting(false);
            }
          }}
          sx={{ marginLeft:'10px' }}
          testID="import-erp-save-button"
          variant="contained"
        >
          Save
        </Button>
      )}
      cancelButton={() => (
        <Button
          color="inherit"
          disabled={isImporting}
          onClick={onClose}
          testID="import-erp-cancel-button"
          variant="text"
        >
          Cancel
        </Button>
      )}
      onClose={onClose}
      open={open}
      testID="import-erp-modal"
      title="ERP Data Import"
    >
      <Text category="title-medium">Select the type of data import</Text>
      <RadioGroup
        onChange={(e) => setSelectedOption(e.target.value as ImportType)}
        value={selectedOption}
      >
        {radioOptions.map((option) => (
          <Fragment key={option}>
            <VSpacer size="3" />
            <Radio testID={`${option}-radio`} value={option.toLowerCase()}>
              <HSpacer size="5" />
              {option}
              <HSpacer size="5" />
              <Button
                color="info"
                onClick={async () => {
                  const csv = await ImportApi.getTemplate(option.toLowerCase() as ImportType);
                  downloadCsv(csv, `${option}-template`);
                }}
                size="small"
                testID={`${option}-template-link`}
                variant="text"
              >
                <Text category="body-small">template</Text>
              </Button>
            </Radio>
            <VSpacer size="3" />
          </Fragment>
        ))}
      </RadioGroup>
      <VSpacer size="5" />
      <Divider />
      <VSpacer size="5" />
      <Text category="title-medium">Upload</Text>
      <VSpacer size="4" />
      <Text category="body-small">Upload CSV file using the template provided</Text>
      <VSpacer size="5" />
      <FileUpload
        acceptedFiles=".csv"
        onChangeFile={(file) => {
          setSelectedFile(file);
          setIsUpdatedAfterError(true);
        }}
      />
      {!!importErrors && (
        <>
          <VSpacer size="5" />
          <Text category="title-medium" color="error">Import not successful</Text>
          <Text category="body-medium">
            An ERP import must pass all required validations to be successful.
            If you receive import errors, please download the attached errors file.
            Once resolved, you must retry the import.
          </Text>
          <TextLink
            category="body-medium"
            onClick={handleDownloadErrorCsv}
            testID="download-errors-text-link"
          >
            Download error results
          </TextLink>
        </>
      )}
    </Modal>
  );
};
