import { Client } from '@/utilities/api/Client';
import { QueryUtil } from '@/utilities/Query';
import { ImportEndpoint } from '@api/endpoints';
import { ImportType } from '@shared/enums';

export class ImportApi {
  static getTemplate (importType: ImportType): Promise<string> {
    return Client(`import/template/${importType}`);
  }

  static getHistory (
    query: ImportEndpoint.GetHistory.Query,
  ): Promise<ImportEndpoint.GetHistory.Response> {
    const queryString = QueryUtil.stringify(query);
    return Client(`import/history?query=${queryString}`);
  }

  static import (retailerId: string, file: File, importType: ImportType) {
    const data = new FormData();
    data.append('file', file);
    return Client(`retailer/${retailerId}/import/${importType}`, {
      body: data,
      method: 'POST',
    });
  }
}
