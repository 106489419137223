import { Button, Text, VSpacer } from '@/components/DesignSystem';
import { Table, TableRow } from '@/components/DesignSystem/Table/Table';
import { QueryKeys } from '@/constants/QueryKeys';
import { useGetRetailerById, useGetRetailerMembers } from '@/hooks/useHierarchyOfRetailers';
import { useSearch } from '@/hooks/useSearch';
import {
  BrandIdentityInputs,
} from '@/pages/Admin/HierarchyOfRetailers/Retailer/BrandIdentityInputs';
import { DescriptionCard } from '@/pages/Admin/HierarchyOfRetailers/Retailer/DescriptionCard';
import { ImportErpModal } from '@/pages/Admin/HierarchyOfRetailers/Retailer/ImportErpModal';
import {
  RewardsProgramsCard,
} from '@/pages/Admin/HierarchyOfRetailers/Retailer/RewardsPrograms/RewardsProgramsCard';
import { useSnackbar } from '@/providers/GlobalSnackbarProvider';
import { HierarchyOfRetailersApi } from '@/utilities/api/HierarchyOfRetailersApi';
import { ImportApi } from '@/utilities/api/ImportApi';
import { RetailerEndpoint } from '@api/endpoints';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import UploadIcon from '@mui/icons-material/Upload';
import { CircularProgress, Container, Divider, Pagination, Stack } from '@mui/material';
import { DateTime } from 'luxon';
import React, { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import LocationCard from './Locations/LocationOverviewSection';
import MembersCard from './Members/MemberOverviewSection';
import RetailerDetailsCard from './RetailerDetailsCard';

const Loader = () => (
  <Container>
    <Stack alignItems='center'>
      <VSpacer size='14' />
      <CircularProgress />
    </Stack>
  </Container>
);

const RetailerDetailsOverviewPage = () => {
  const { openSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const [showAddMember, setShowAddMember] = useState(false);
  const [showViewMember, setShowViewMember] = useState(false);
  const [showLocation, setShowLocation] = useState(false);
  const [showImportModal, setShowImportModal] = useState(false);
  const [historyPage, setHistoryPage] = useState(0);

  const { id = '' } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const backToAllRetailers = () => navigate(-1);

  const onAddMember = () => setShowAddMember(!showAddMember);
  const onViewMember = () => setShowViewMember(!showViewMember);
  const onAddLocation = () => setShowLocation(!showLocation);
  const { debouncedSearch, search, setSearch } = useSearch();

  const { retailerMembers, isLoading } = useGetRetailerMembers(id, debouncedSearch);

  const { data: importHistoryResult } = useQuery(
    [QueryKeys.GET_IMPORT_HISTORY_LIST, id],
    () => ImportApi.getHistory({ limit: 5, page: historyPage, retailerId: id }),
    { enabled: !!id },
  );

  const { retailer } = useGetRetailerById(id ?? '');

  const { mutate: updateRetailer } = useMutation(
    (updates: RetailerEndpoint.Update.Request) => (
      HierarchyOfRetailersApi.updateRetailer(id, updates)
    ),
    {
      onSuccess: async (_, updates) => {
        if (!retailer?.description && updates.description) {
          openSnackbar('Description added');
        } else if (retailer?.description && updates.description) {
          openSnackbar('Description updated');
        } else if (retailer?.description && updates.description === null) {
          openSnackbar('Description deleted');
        }

        if (updates.image) {
          openSnackbar('Logo uploaded');
        } else if (updates.image === null) {
          openSnackbar('Logo deleted');
        }

        if (!retailer?.brandColor && updates.brandColor) {
          openSnackbar('Brand color added');
        } else if (retailer?.brandColor && updates.brandColor) {
          openSnackbar('Brand color updated');
        } else if (retailer?.brandColor && updates.brandColor === null) {
          openSnackbar('Brand color deleted');
        }

        if (!retailer?.templateType && updates.templateType) {
          openSnackbar('Deliverable template added');
        } else if (retailer?.templateType && updates.templateType) {
          openSnackbar('Deliverable template updated');
        } else if (retailer?.templateType && updates.templateType === null) {
          openSnackbar('Deliverable template deleted');
        }

        await queryClient.invalidateQueries(QueryKeys.GET_RETAILER_BY_ID);
      },
      onError: (error: Error) => {
        openSnackbar(error.message || 'An error has occurred');
      },
    },
  );

  const { data: categories } = useQuery(
    [QueryKeys.GET_RETAILER_CATEGORIES, id],
    async () => {
      const result = await HierarchyOfRetailersApi.getRetailerCategories(id);
      return result.filter(({ name }) => name);
    },
  );

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Container maxWidth="lg">
      <Stack direction="row" justifyContent="space-between" py="24px">
        <Button
          color="inherit"
          onClick={backToAllRetailers}
          startIcon={<KeyboardBackspaceIcon />}
          testID="back-to-all-retailers"
          variant="text"
        >
          Back to all retailers
        </Button>
        <Button
          color="inherit"
          onClick={() => setShowImportModal(true)}
          startIcon={<UploadIcon />}
          testID="import-erp-button"
          variant="text"
        >
          Import ERP data
        </Button>
      </Stack>
      <Stack spacing="2">
        <RetailerDetailsCard />
        <VSpacer size="5" />
        <Divider />
        <VSpacer size="5" />
        <DescriptionCard
          description={retailer?.description}
          onChange={(description) => (
            updateRetailer({ description: description || null })
          )}
        />
        <VSpacer size="5" />
        {!!retailer && (
          <>
            <BrandIdentityInputs
              onChange={updateRetailer}
              retailer={retailer}
            />
            <VSpacer size="5" />
          </>
        )}
        <Divider />
        <VSpacer size="5" />
        <MembersCard
          members={retailerMembers}
          onAddMember={onAddMember}
          onViewMember={onViewMember}
          search={search}
          setSearch={setSearch}
          showAddMember={showAddMember}
          showViewMember={showViewMember}
        />
        <VSpacer size="5" />
        <Divider />
        <VSpacer size="5" />
        <LocationCard
          isRetailerActive={retailer?.isActive ?? false}
          onAddLocation={onAddLocation}
          retailerId={id}
          show={showLocation}
        />
        <VSpacer size="5" />
        <Divider />
        <VSpacer size="5" />
        {!!retailer && !!categories?.length && (
          <>
            <RewardsProgramsCard
              retailerId={retailer.id}
              rewardsProgram={retailer?.rewardsPrograms?.[0]}
            />
            <VSpacer size="5" />
            <Divider />
          </>
        )}
        {!!importHistoryResult?.total && (
          <Stack>
            <VSpacer size="5" />
            <Text category="body-xlarge">ERP Imports</Text>
            <VSpacer size="5" />
            <Table
              headers={['Import date', 'Import type', 'File name']}
            >
              {importHistoryResult.data.map((history) => (
                <TableRow
                  key={history.id}
                  values={[
                    DateTime.fromJSDate(
                      history.createdAt, { zone: 'America/New_York' },
                    ).toFormat('MMM dd, yyyy'),
                    history.type.charAt(0).toUpperCase() + history.type.slice(1),
                    history.fileName,
                  ]}
                />
              ))}
            </Table>
            {importHistoryResult.lastPage > 0 && (
              <>
                <VSpacer size="5" />
                <Pagination
                  count={importHistoryResult.lastPage + 1}
                  onChange={(_, page) => {
                    setHistoryPage(page - 1);
                  }}
                  page={importHistoryResult.page + 1}
                  sx={{ alignSelf: 'center' }}
                />
              </>
            )}
            <VSpacer size="5" />
          </Stack>
        )}
      </Stack>
      {showImportModal && (
        <ImportErpModal
          onClose={() => setShowImportModal(false)}
          open
          retailerId={id}
        />
      )}
    </Container>
  );
};

export default RetailerDetailsOverviewPage;
